import Modal from 'bootstrap/js/dist/modal';
import Tab from 'bootstrap/js/dist/tab';
import Toast from 'bootstrap/js/dist/toast';
import PhotoSwipeLightbox from './../../node_modules/photoswipe/dist/photoswipe-lightbox.esm.js';
import './bootstrap';
import Swiper, { Navigation } from 'swiper';

(function ($) {
    "use strict";

    var $window = $(window),
        $body = $('body');

    /*--
        Custom script to call Background
        Image & Color from html data attribute
    -----------------------------------*/
    // $('[data-bg-image]').each(function () {
    //     var $this = $(this),
    //         $image = $this.data('bg-image');
    //     $this.css('background-image', 'url(' + $image + ')');
    // });
    $('[data-bg-color]').each(function () {
        var $this = $(this),
            $color = $this.data('bg-color');
        $this.css('background-color', $color);
    });

    Livewire.on('updatedProductOptions', function() {
        // $('[data-bg-image]').each(function () {
        //     var $this = $(this),
        //         $image = $this.data('bg-image');
        //     $this.css('background-image', 'url(' + $image + ')');
        // });
        $('[data-bg-color]').each(function () {
            var $this = $(this),
                $color = $this.data('bg-color');
            $this.css('background-color', $color);
        });
    });

    Livewire.on('add-to-cart', function() {
        $('.header-cart .offcanvas-toggle').click();
    });

    Livewire.on('showReview', function() {
        $body.addClass('offcanvas-open');
        $('#offcanvas-review').addClass('offcanvas-open');
        $('.offcanvas-overlay').fadeIn();
    });

    Livewire.on('cart-toast-show', function(message) {
        $('.toast-quantity .toast-body').text(message);
        $('.toast-quantity').toast();
        $('.toast-quantity').toast('show');
    });

    /*--
        Sub Menu & Mega Menu Alignment
    -----------------------------------*/
    var subMenuMegaMenuAlignment = () => {
        var $this,
            $subMenu,
            $megaMenu,
            $siteMainMenu = $('.site-main-menu');

        $siteMainMenu.each(function () {
            $this = $(this);
            if ($this.is('.site-main-menu-left, .site-main-menu-right') && $this.closest('.section-fluid').length) {
                $megaMenu = $this.find('.mega-menu');
                $this.css("position", "relative");
                if ($this.hasClass('site-main-menu-left')) {
                    $megaMenu.css({
                        "left": "0px",
                        "right": "auto"
                    });
                } else if ($this.hasClass('site-main-menu-left')) {
                    $megaMenu.css({
                        "right": "0px",
                        "left": "auto"
                    });
                }
            }
        });
        $subMenu = $('.sub-menu');
        if ($subMenu.length) {
            $subMenu.each(function () {
                $this = $(this);
                var $elementOffsetLeft = $this.offset().left,
                    $elementWidth = $this.outerWidth(true),
                    $windowWidth = $window.outerWidth(true) - 10,
                    isElementVisible = ($elementOffsetLeft + $elementWidth < $windowWidth);
                if (!isElementVisible) {
                    if ($this.hasClass('mega-menu')) {
                        var $this = $(this),
                            $thisOffsetLeft = $this.parent().offset().left,
                            $widthDiff = $windowWidth - $elementWidth,
                            $left = $thisOffsetLeft - ($widthDiff / 2);
                        $this.attr("style", "left:" + -$left + "px !important").parent().css("position", "relative");
                    } else {
                        $this.parent().addClass('align-left');
                    }
                } else {
                    $this.removeAttr('style').parent().removeClass('align-left');
                }
            });
        }
    }

    /*--
        Off Canvas Function
    -----------------------------------*/
    (function () {
        var $offCanvasToggle = $('.offcanvas-toggle'),
            $offCanvas = $('.offcanvas'),
            $offCanvasOverlay = $('.offcanvas-overlay'),
            $mobileMenuToggle = $('.mobile-menu-toggle');
        $offCanvasToggle.on('click', function (e) {
            e.preventDefault();
            var $this = $(this),
                $target = $this.attr('href');
            $body.addClass('offcanvas-open');
            $($target).addClass('offcanvas-open');
            $offCanvasOverlay.fadeIn();
            if ($this.parent().hasClass('mobile-menu-toggle')) {
                $this.addClass('close');
            }
        });
        $('.offcanvas-close, .offcanvas-overlay').on('click', function (e) {
            e.preventDefault();
            $body.removeClass('offcanvas-open');
            $offCanvas.removeClass('offcanvas-open');
            $offCanvasOverlay.fadeOut();
            $mobileMenuToggle.find('a').removeClass('close');
        });
    })();

    /*--
        Off Canvas Menu
    -----------------------------------*/
    function mobileOffCanvasMenu() {
        var $offCanvasNav = $('.offcanvas-menu, .overlay-menu'),
            $offCanvasNavSubMenu = $offCanvasNav.find('.sub-menu');

        /*Add Toggle Button With Off Canvas Sub Menu*/
        $offCanvasNavSubMenu.parent().prepend('<span class="menu-expand"></span>');

        /*Category Sub Menu Toggle*/
        $offCanvasNav.on('click', 'li a, .menu-expand', function (e) {
            var $this = $(this);
            if ($this.attr('href') === '#' || $this.hasClass('menu-expand')) {
                e.preventDefault();
                if ($this.siblings('ul:visible').length) {
                    $this.parent('li').removeClass('active');
                    $this.siblings('ul').slideUp();
                    $this.parent('li').find('li').removeClass('active');
                    $this.parent('li').find('ul:visible').slideUp();
                } else {
                    $this.parent('li').addClass('active');
                    $this.closest('li').siblings('li').removeClass('active').find('li').removeClass('active');
                    $this.closest('li').siblings('li').find('ul:visible').slideUp();
                    $this.siblings('ul').slideDown();
                }
            }
        });
    }
    mobileOffCanvasMenu();

    var $home3Slider = new Swiper('.home3-slider', {
        loop: true,
        speed: 750,
        effect: 'fade',
        modules: [Navigation],
        navigation: {
            nextEl: '.home3-slider-next',
            prevEl: '.home3-slider-prev',
        },
        autoplay: {},
    });

    // Product Slider
    $('.product-carousel').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        // focusOnSelect: true,
        prevArrow: '<button class="slick-prev"><i class="ti-angle-left"></i></button>',
        nextArrow: '<button class="slick-next"><i class="ti-angle-right"></i></button>',
        responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 3
            }
        },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    // Single Product Slider
    $('.product-gallery-slider').slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.product-thumb-slider, .product-thumb-slider-vertical',
        prevArrow: '<button class="slick-prev"><i class="ti-angle-left"></i></button>',
        nextArrow: '<button class="slick-next"><i class="ti-angle-right"></i></button>'
    });
    $('.product-thumb-slider').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        focusOnSelect: true,
        asNavFor: '.product-gallery-slider',
        prevArrow: '<button class="slick-prev"><i class="ti-angle-left"></i></button>',
        nextArrow: '<button class="slick-next"><i class="ti-angle-right"></i></button>'
    });

    Livewire.on('updatedProductOptions', function() {
        $('.product-gallery-slider').slick({
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.product-thumb-slider, .product-thumb-slider-vertical',
            prevArrow: '<button class="slick-prev"><i class="ti-angle-left"></i></button>',
            nextArrow: '<button class="slick-next"><i class="ti-angle-right"></i></button>'
        });
        $('.product-thumb-slider').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            focusOnSelect: true,
            asNavFor: '.product-gallery-slider',
            prevArrow: '<button class="slick-prev"><i class="ti-angle-left"></i></button>',
            nextArrow: '<button class="slick-next"><i class="ti-angle-right"></i></button>'
        });
    });

    /*--
        CountDown
    -----------------------------------*/
    $('[data-countdown]').each(function () {
        var $this = $(this),
            $finalDate = $this.data('countdown');
        $this.countdown($finalDate, function (event) {
            $this.html(event.strftime('<div class="count"><span class="amount">%-D</span><span class="period">Days</span></div><div class="count"><span class="amount">%-H</span><span class="period">Hours</span></div><div class="count"><span class="amount">%-M</span><span class="period">Minutes</span></div><div class="count"><span class="amount">%-S</span><span class="period">Seconds</span></div>'));
        });
    });

    /* Modal */
    // $('#quickViewModal').on('shown.bs.modal', function (e) {
    //     // $('.product-gallery-slider-quickview').slick({
    //     //     dots: true,
    //     //     infinite: true,
    //     //     slidesToShow: 1,
    //     //     slidesToScroll: 1,
    //     //     prevArrow: '<button class="slick-prev"><i class="ti-angle-left"></i></button>',
    //     //     nextArrow: '<button class="slick-next"><i class="ti-angle-right"></i></button>'
    //     // });
    //     $('.product-gallery-slider').slick({
    //         dots: true,
    //         infinite: true,
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         prevArrow: '<button class="slick-prev"><i class="ti-angle-left"></i></button>',
    //         nextArrow: '<button class="slick-next"><i class="ti-angle-right"></i></button>'
    //     });
    // })

    Livewire.on('showQuickViewModal', function() {
        $('#quickViewModal').modal('show');
    });

    /*--
        Single Product Gallery Popup
    -----------------------------------*/
    // var $productPopupGalleryBtn = $('.product-gallery-popup'),
    //     $productPopupGallery = $productPopupGalleryBtn.data('images'),
    //     $openPhotoSwipe = function () {
    //         var pswpElement = $('.pswp')[0],
    //             items = $productPopupGallery,
    //             options = {
    //                 history: false,
    //                 focus: false,
    //                 shareEl: false,
    //                 closeOnScroll: false,
    //                 showAnimationDuration: 0,
    //                 hideAnimationDuration: 0
    //             };
    //         new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options).init();
    //     };
    // $productPopupGalleryBtn.on('click', $openPhotoSwipe);

    const lightbox = new PhotoSwipeLightbox({
        // may select multiple "galleries"
        gallery: '.product-gallery-slider',
        children: 'a',
        pswpModule: () => import('./../../node_modules/photoswipe/dist/photoswipe.esm.js')
    });
    lightbox.init();

    /*--
        On Load Function
    -----------------------------------*/
    $window.on('load', function () {
        subMenuMegaMenuAlignment();
    });

    /*--
        Resize Function
    -----------------------------------*/
    $window.resize(function () {
        subMenuMegaMenuAlignment();
    });

    if ( ! getCookie('popupRegisterBonus')) {
        $('#popupRegisterBonus').modal('show');
    }

    $('#popupRegisterBonus').on('hidden.bs.modal', function() {
        setCookie('popupRegisterBonus', true, 30); // Set the cookie to expire after 30 days
    });

    // if ( ! getCookie('blackfriday')) {
    //     $('#blackfriday').modal('show');
    // }
    //
    // $('#blackfriday').on('hidden.bs.modal', function() {
    //     setCookie('blackfriday', true, 5); // Set the cookie to expire after 30 days
    // });

    if ( ! getCookie('popup2w12')) {
        $('#popup2w1').modal('show');
    }

    $('#popup2w1').on('hidden.bs.modal', function() {
        setCookie('popup2w12', true, 1); // Set the cookie to expire after 30 days
    });

    // if ( ! getCookie('popupUrlop')) {
    //     // $('#popupRegisterBonus').modal('show');
    //     $('#popupUrlop').modal('show');
    // }

    // $('#popupUrlop').on('hidden.bs.modal', function() {
    //     setCookie('popupUrlop', true, 1); // Set the cookie to expire after 30 days
    // });
})(jQuery);

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// Function to check if a cookie exists
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return true;
        }
    }
    return false;
}
